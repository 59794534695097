import ExpandToggle from '@threespot/expand-toggle/index';

export default class NavItems {
  constructor(selector) {
    this.items = document.querySelectorAll(selector);

    if (this.items.length === 0) {
      console.error(`No elements with the ${selector} selector exists`);
      return null;
    }

    this.customEvent = new CustomEvent('nav-item-expanded'); // used to close subnav menus

    this.toggles = Array.from(this.items).map((item) => {
      let toggle = new ExpandToggle(item);
      let parent = item.parentElement;

      toggle.on('expand', () => {
        document.documentElement.dispatchEvent(this.customEvent);
        this.collapseAll(toggle);

        // adds the yellow border color to the toggle's button container
        parent.style.setProperty('--border-color', '#efb600');
      });

      toggle.on('collapse', () => {
        // adds the transparent border color to the toggle's button container
        parent.style.setProperty('--border-color', 'transparent');
      });

      return toggle;
    });
  }

  collapseAll(except) {
    this.toggles.forEach((toggle) => {
      if (toggle !== except) {
        toggle.collapse();
      }
    });
  }
}
