// Detect input method in order to hide outlines in an accessible manner
// https://github.com/ten1seven/what-input
// NOTE: Can drop once Safari supports :focus-visible to save 1.7kB (gzip)
//       https://caniuse.com/css-focus-visible
import 'what-input';

// Emit “font-loaded” event so expandable components know to recalc their height
document.fonts.ready.then(function () {
  let fontEvent = new CustomEvent('fonts-loaded');
  document.documentElement.dispatchEvent(fontEvent);
});

// Libs
import Tabs from './lib/tabs';

// Link icons
import './modules/link-icons';

// Components
import './components/carousel';

// Tabs
document.querySelectorAll('[data-tab]').forEach((tab) => new Tabs(tab));

// Navigation
import NavItems from './modules/nav-items';
new NavItems('[data-nav-toggles]');

import mobileNavToggle from './modules/nav-mobile-toggle';
new mobileNavToggle('[data-expands="Nav"]');

// Newsletter
import ValidateForm from './lib/form-validation';

let footerNewsletter = document.querySelector('[data-footer-newsletter]');
if (footerNewsletter) {
  new ValidateForm(footerNewsletter);
}
