import Flickity from 'flickity';

let carousel = document.querySelector('.Carousel');

if (carousel) {
  new Flickity('.Carousel', {
    accessibility: true,// enable keyboard nav, pressing left & right keys
    adaptiveHeight: true,
    autoPlay: false,
    cellAlign: 'center',// alignment of items
    cellSelector: '.Carousel-item',
    contain: true,
    draggable: '>1',// enables dragging & flicking if at least 2 cells
    groupCells: 1,
  });
}
